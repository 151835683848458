import { Exam } from '@repo/types/admin'
import { getExamsByPlatformQuery } from './get-exams-by-platform.query'
import { adminClient } from '@repo/utils'

export const getExamsByPlatform = async (body: {
  platformId: number
  admin?: boolean
}): Promise<Exam[]> => {
  const data = await adminClient.request<{ getExamsByPlatform: Exam[] }>(
    getExamsByPlatformQuery,
    { getExamsByPlatformInput: body }
  )

  return data.getExamsByPlatform
}
