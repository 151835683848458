import useSWR from 'swr'
import { useAdminToken } from '@repo/utils/fetcher/admin-fetcher'
import { getPlatforms } from './get-platforms'

export function useGetPlatforms() {
  const token = useAdminToken((state) => state.token)
  return useSWR(token ? ['platforms', token] : null, getPlatforms, {
    revalidateOnFocus: false,
  })
}
