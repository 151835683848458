import {
  Arrow,
  Content,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip'
import { styled } from '@repo/ui/config'

export function Tooltip({
  side = 'right',
  trigger,
  children,
}: {
  side?: 'right' | 'left' | 'top' | 'bottom'
  trigger: any
  children: any
}) {
  return (
    <Provider delayDuration={100} skipDelayDuration={100}>
      <Root delayDuration={350}>
        <Trigger asChild>{trigger}</Trigger>
        <StyledContent side={side} sideOffset={5}>
          {children}
          <StyledArrow width={15} height={10} />
        </StyledContent>
      </Root>
    </Provider>
  )
}

const StyledContent = styled(Content, {
  background: '$white',
  padding: '12px',
  borderRadius: '$2',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
  'z-index': 1,
})

const StyledArrow = styled(Arrow, {
  fill: '$white',
})
