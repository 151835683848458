'use client'
import { SessionResolver } from '@repo/users/ui/session-resolver'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { UserPermissionsType } from '@repo/types/common/roles'
import { Spinner } from '@repo/ui/components/spinner'
import {
  selectedPlatformSelector,
  setSelectedPlatformSelector,
  useSelectedPlatform,
} from '../../hooks/use-selected-platform'
import { CloseSession } from '../../screens/exam/close-sesion'
import { CreateExam } from '../../screens/exam/create-exam'
import { Stats } from '../../screens/exam/stats'
import { useExamsByPlatform } from '../../services/api/exams/use-get-exams-by-platform'
import { useGetPlatforms } from '../../services/api/platforms/use-get-platforms'
import { AccessView } from '../access'
import { Ninja } from '../icons/ninja'
import { ContentRoute } from '../layout/components/content-route'
import { NotificationRoute } from '../layout/components/notifications-route'
import { UsersRoute } from '../layout/components/users-route'
import { CaseStudyRoute } from './components/case-study-route'
import { Empty } from './components/empty'
import { EnvironmentAlert } from './components/environment-alert'
import { ImpugnmentRoute } from './components/impugnment-route'
import { MenuItemExam } from './components/menu-item-exam'
import { CommentMenuButton } from './components/menu/comment-menu-button'
import { PsychotechnicalRoute } from './components/psychotechnical-route'
import { Select } from './components/select'
import { SimulacrumsRoute } from './components/simulacrums-route'
import { ThemesRoute } from './components/themes-route'

export function Layout({
  children,
  loading,
}: {
  loading?: boolean
  children?: any
}) {
  const router = useRouter()
  const selectedPlatform = useSelectedPlatform(selectedPlatformSelector)
  const setSelectedPlatform = useSelectedPlatform(setSelectedPlatformSelector)
  const { data: session, status } = useSession()
  const { data } = useGetPlatforms()
  const { data: exams } = useExamsByPlatform(
    selectedPlatform?.id || data?.[0]?.id
  )

  useEffect(() => {
    if (!selectedPlatform && data?.[0]) {
      setSelectedPlatform(data[0])
    }
  }, [data, selectedPlatform, setSelectedPlatform])

  return (
    <SessionResolver session={session} isLoading={status === 'loading'}>
      <div className="flex h-full min-h-full w-full flex-col overflow-hidden bg-admin-grey-100">
        <EnvironmentAlert />
        <div className="flex h-full w-full flex-row">
          <aside className="sticky top-0 z-40 flex h-full w-[14rem] min-w-[14rem] flex-col justify-between overflow-y-scroll border-r border-black/10 bg-white">
            <div className="flex w-full flex-col">
              <div className="pb-[38px] pl-6 pt-3">
                <Ninja style={{ width: 114, height: 45 }} />
              </div>
              <div className="pl-6">
                <AccessView
                  permission={UserPermissionsType.COURSE_CLASS_MANAGEMENT}>
                  <ContentRoute />
                </AccessView>
                <AccessView
                  permission={UserPermissionsType.IMPUGMENTS_MANAGEMENT}>
                  <ImpugnmentRoute />
                </AccessView>
                <AccessView permission={UserPermissionsType.TEST_MANAGEMENT}>
                  <ThemesRoute />
                </AccessView>
                <AccessView permission={UserPermissionsType.PSYCHOS_MANAGEMENT}>
                  <PsychotechnicalRoute />
                </AccessView>
                <AccessView
                  permission={UserPermissionsType.CASE_STUDY_MANAGEMENT}>
                  <CaseStudyRoute />
                </AccessView>
                <AccessView
                  permission={UserPermissionsType.SIMULACRUM_MANAGEMENT}>
                  <SimulacrumsRoute />
                </AccessView>
                <AccessView permission={UserPermissionsType.USER_MANAGEMENT}>
                  <UsersRoute />
                </AccessView>
                <AccessView permission={UserPermissionsType.COMMENTS_EDIT}>
                  <CommentMenuButton />
                </AccessView>
                <AccessView
                  permission={UserPermissionsType.NOTIFICATIONS_ACCESS}>
                  <NotificationRoute />
                </AccessView>
              </div>
              <div className="flex w-full flex-col pl-3">
                <AccessView permission={UserPermissionsType.EXAM_MANAGEMENT}>
                  <div className="relative z-50 mt-5 pr-3">
                    {data && data.length > 0 ? (
                      <Select
                        name="platform"
                        disabled={data.length === 1}
                        value={
                          selectedPlatform?.id.toString() ||
                          data[0].id.toString()
                        }
                        items={
                          data.map((platform) => ({
                            value: platform.id.toString(),
                            label: platform.name,
                          })) || []
                        }
                        onChange={(item) => {
                          router.push('/')
                          setSelectedPlatform(
                            data.find((p) => p.id === Number(item))
                          )
                        }}
                      />
                    ) : (
                      <Empty />
                    )}
                    <div className="mt-1 pl-6">
                      {exams &&
                        exams.length > 0 &&
                        exams.map((opo) => {
                          return <MenuItemExam opo={opo} key={opo.id} />
                        })}
                      {!exams && [1, 2, 3, 4, 5].map((n) => <Empty key={n} />)}
                    </div>
                  </div>
                </AccessView>
              </div>
            </div>
            <div className="flex flex-col pb-5 pl-6 pt-10">
              <AccessView permission={UserPermissionsType.EXAM_MANAGEMENT}>
                <Stats />
                <CreateExam />
              </AccessView>
              <CloseSession />
            </div>
          </aside>
          <div className="relative flex h-[calc(100%-28px)] w-[calc(100%-224px)] flex-col overflow-hidden">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner color="$blue100" />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </SessionResolver>
  )
}
