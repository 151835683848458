import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Exam } from '@repo/types/admin'
import { Flex } from '@repo/ui/components/flex'
import { getExamIcon } from '@repo/ui/icons/get-exam-icon'
import { slugify } from '@repo/utils/slugify'
import { Tooltip } from './tooltip'

export function MenuItemExam({ opo }: { opo: Exam }) {
  const localPathname = usePathname()

  const Icon = getExamIcon(opo.name)

  return (
    <Link
      href={`/catalog/${opo.id}-${slugify(opo.name)}`}
      passHref
      key={opo.name}>
      <Flex
        as="div"
        key={opo.name}
        css={{
          textDecoration: 'none',
          color: localPathname.includes(
            `/catalog/${opo.id}-${slugify(opo.name)}`
          )
            ? '#0866F2'
            : '#858585',
          alignItems: 'center',
          spacingX: '12px',
          cursor: 'pointer',
          padding: '0px 12px',
          height: 40,
          marginLeft: -11,

          zIndex: 1,
          width: '105%',
          borderRadius: '$2',
          backgroundColor: localPathname.includes(
            `/oposicion/${opo.id}-${slugify(opo.name)}`
          )
            ? 'rgba(8, 102, 242, 0.04)'
            : 'transparent',
          transition: '200ms ease-in-out',
        }}>
        <figure className="flex h-[16px] w-[16px] items-center justify-center">
          <Icon
            active
            className={clsx([
              'h-[16px] max-h-[16px] min-h-[16px] w-[16px] min-w-[16px] max-w-[16px]',
              {
                grayscale: !opo.available,
                'opacity-50': !opo.available,
              },
            ])}
          />
        </figure>
        <Tooltip
          trigger={
            <p className="overflow-hidden truncate text-[14px]">{opo.name}</p>
          }>
          <div className="flex">
            <p className="text-sm font-medium text-[#858585]">{opo.name}</p>
            <p className="ml-5 text-admin-grey-500">
              &#40;{opo.questionsAmount}&#41;
            </p>
          </div>
        </Tooltip>
      </Flex>
    </Link>
  )
}
