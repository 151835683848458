import { styled } from '@repo/ui'

function Svg(props) {
  return (
    <svg
      id="Capa_2_G"
      width="18"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}>
      <defs>
        <style>{'.cls-1F{fill:#e30613;}.cls-2F{fill:#fff;}'}</style>
      </defs>
      <g id="Capa_2_F" data-name="Capa_2_F">
        <g id="Capa_1-2_F" data-name="Capa_1-2_F">
          <circle className="cls-1F" cx="250" cy="250" r="250" />
          <path
            className="cls-2F"
            d="M426.8,345.79H365c0-47.09-13.88-82.42-42.43-108C277.13,197,205.42,190.87,166.4,190.87V345.79H104.58V133.64L132,130.56c5.92-.67,146.1-15.43,231.54,61C405.53,229,426.8,280.92,426.8,345.79Z"
          />
        </g>
      </g>
    </svg>
  )
}

export const FN = styled(Svg)
