import { PropsWithChildren } from 'react'
import { UserPermissionsType } from '@repo/types/common'
import { usePermissions } from '../hooks/use-permission'
import { Tooltip } from './layout/components/tooltip'

export type AccessViewProps = PropsWithChildren & {
  permission: UserPermissionsType | UserPermissionsType[]
}
export type DisableViewProps = PropsWithChildren & {
  notPermission: UserPermissionsType | UserPermissionsType[]
}

export function AccessView({ permission, children }: AccessViewProps) {
  const { hasPermission, hasAnyPermission } = usePermissions()

  if (Array.isArray(permission)) {
    return <>{hasAnyPermission(permission) && <>{children}</>}</>
  }

  return <>{hasPermission(permission) && <>{children}</>}</>
}

export function DisableView({ notPermission, children }: DisableViewProps) {
  const { hasPermission, hasAnyPermission } = usePermissions()

  if (Array.isArray(notPermission)) {
    return hasAnyPermission(notPermission) ? (
      <>{children}</>
    ) : (
      <>
        <Tooltip
          side={'bottom'}
          trigger={
            <div>
              <div className="pointer-events-none opacity-50">{children}</div>
            </div>
          }>
          <h1>Sin permisos</h1>
        </Tooltip>
      </>
    )
  }

  return hasPermission(notPermission) ? (
    <>{children}</>
  ) : (
    <>
      <Tooltip
        side={'bottom'}
        trigger={
          <div>
            <div className="pointer-events-none opacity-50">{children}</div>
          </div>
        }>
        <h1>Sin permisos</h1>
      </Tooltip>
    </>
  )
}
