import { createExamMutation } from './create-exam.mutation'
import {
  CreateExamMutationMutation,
  CreateExamMutationMutationVariables,
} from '@repo/types/admin'
import { adminClient } from '@repo/utils'

export const createExam = async (
  args: CreateExamMutationMutationVariables['createExamInput']
) => {
  try {
    const data = await adminClient.request<CreateExamMutationMutation>(
      createExamMutation,
      {
        createExamInput: args,
      }
    )

    return data.createExam
  } catch (error) {
    throw new Error(error)
  }
}
