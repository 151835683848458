import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import { IconProvider } from '@repo/ui/components/icons/icon-provider'
import { LateralButton } from './lateral-button'

export function CommentMenuButton() {
  const pathnname = usePathname()
  const isSelected = pathnname.includes('comments')

  return (
    <LateralButton pathname="/comments">
      <IconProvider
        title="IoChatbox"
        className={isSelected ? 'fill-admin-blue' : 'fill-[#AFAFAF]'}
        width={16}
        height={16}
      />
      <span
        className={clsx(
          'text-sm font-semibold',
          isSelected ? 'text-admin-blue' : 'text-[#858585]'
        )}>
        Comentarios
      </span>
    </LateralButton>
  )
}
