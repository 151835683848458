import { Platform } from '@repo/types/admin'

import { getPlatformsQueryAdmin } from './get-platforms.query'
import { adminClient } from '@repo/utils'

type Data = {
  getPlatformsAdmin: Platform[]
}

export const getPlatforms = async (): Promise<Platform[]> => {
  const data: Data = await adminClient.request(getPlatformsQueryAdmin)

  return data.getPlatformsAdmin
}
