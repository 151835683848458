import * as SelectPrimitive from '@radix-ui/react-select'
import { styled } from '@repo/ui/config'
import { ChevronDown } from '@repo/ui/icons/chevron-down'
import { ChevronUp } from '@repo/ui/icons/chevron-up'
import { SelectItem } from './select-item'

export function Select({
  items,
  name,
  onChange,
  disabled,
  value,
}: {
  value: string
  disabled?: boolean
  name: string
  onChange: (value: string) => void
  items: { value: string; label: string }[]
}) {
  return (
    <SelectPrimitive.Root
      name={name}
      onValueChange={onChange}
      value={value}
      defaultValue={items?.[0]?.value}>
      <StyledTrigger
        className="w-full bg-black/[0.03] transition-all hover:bg-black/[0.05]"
        css={{ width: '100%' }}
        aria-label={name}>
        <div className="w-full pl-3">
          <SelectPrimitive.Value className="w-full" />
        </div>
        <SelectPrimitive.Icon className="mr-3">
          <ChevronDown css={{ transform: 'rotate(180deg)' }} />
          <ChevronDown css={{ marginTop: -9 }} />
        </SelectPrimitive.Icon>
      </StyledTrigger>
      <SelectPrimitive.Portal className="z-50">
        <StyledContent>
          <StyledScrollUpButton>
            <ChevronUp />
          </StyledScrollUpButton>
          <StyledViewport>
            <SelectPrimitive.Group>
              {items.map((item, index) => (
                <SelectItem key={index} item={item} disabled={disabled} />
              ))}
            </SelectPrimitive.Group>
          </StyledViewport>
          <StyledScrollDownButton>
            <ChevronDown />
          </StyledScrollDownButton>
        </StyledContent>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  )
}

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  fontSize: 13,
  lineHeight: 1,
  height: 40,
  gap: 5,
  cursor: 'pointer',
  color: '$adminGrey500',
  '&:hover': { backgroundColor: '$adminGrey200' },
})

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 6,
  zIndex: 50,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
})

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
})

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: '$adminGrey500',
  cursor: 'default',
}

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
)

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
)
